import React from 'react';
import { Animated } from 'react-animated-css';
import { Jumbotron } from 'reactstrap';
import Modal from './modal';
import Form from './partials/form';
import Campaign from './partials/campaign';

class Ads extends React.Component {

    state = {
        campaigns: [], modal: false,
        mode: 1,
        campaing: {}
    }

    // Lifecycle
    componentWillMount = () => {
        const { params, familyActions, leagueActions } = this.props;
        familyActions.fetchCommunities && familyActions.fetchCommunities();
        leagueActions && leagueActions.fetchLeaguesAdmin();
        leagueActions && leagueActions.fetchCampaigns();
        if (params && params.idCampaign) {
            leagueActions && leagueActions.fetchCampaign(params.idCampaign);
            this.setState({ mode: 2 })
        }
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({ bootstrapped: true, campaigns: nextProps.campaigns });
    }
    //

    onChange = () => { this.setState({ searchText: this.txtSearch.value }); }

    addAds = () => { this.setState({ modal: true }) }

    render() {

        const { bootstrapped, modal, mode, campaigns } = this.state;

        return <section style={{ position: 'relative' }}>
            {/*<LineLoader />*/}
            <Animated isVisible={mode === 1} animateOnMount={false} animationIn="fadeInLeft" animationOut="fadeOutLeft" style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
                {/* LEFT MENU */}


                {/* TOP FILTERS */}
                <div style={{ position: 'absolute', top: 0, left: 90, right: 0, height: '3em' }} >
                    <div className="w3-container mt-4">

                        <div className="w3-content d-flex flex-row montserrat" style={{ maxWidth: 1285 }}>

                            <div className="w-50 black">
                                <h1>Ads</h1>
                            </div>

                            <div className="w-100 d-flex flex-row">

                                <input placeholder="Filter by Season's Name" className="form-control  align-self-center" ref={i => this.txtSearch = i} onChange={this.onChange} />

                                <button onClick={() => this.setState({ mode: 2 })} style={{ border: '1px solid #2cad3c', borderRadius: 20, width: 130 }} className="green ml-2 p-2 align-self-center block">
                                    <i className="fas fa-plus margin-right-half" />Add</button>

                            </div>
                        </div>
                    </div>
                </div>

                {bootstrapped && campaigns && (
                    <div key="campaigns" className="d-flex flex-wrap justify-content-center league-list margin-bottom" style={{ paddingTop: 90, paddingLeft: 90 }}>
                        <div className="w3-container mt-4">
                            <div className="w3-content" style={{ maxWidth: 1330 }}>
                                {campaigns.length === 0 && <Jumbotron className="w-100 bg-white d-flex flex-column justify-content-center">
                                    <i className="far fa-meh-blank font-30 black align-self-center" />
                                    <span className="black font-20 align-self-center">There are no Ad Campaings{this.txtSearch.value ? ` named like '${this.txtSearch.value}'` : ''}</span>
                                    <button onClick={() => this.setState({ mode: 2 })} style={{ width: 130 }} className="green ml-2 p-2 align-self-center block">
                                        <i className="fas fa-plus margin-right-half" />Add Campaing</button>
                                </Jumbotron>}
                                <div className="d-flex flex-wrap  justify-content-start">
                                    {campaigns.map((ad, i) => <Campaign key={i} index={i} campaign={ad} fnClick={() => {
                                        this.setState({ selected: ad, mode: 2 });
                                    }} />)}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Animated>

            <Animated isVisible={mode > 1} animateOnMount={false} animationIn="fadeInRight" animationOut="fadeOutRight" style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
                <button className="w3-content d-flex flex-row p-4 w-100" onClick={() => this.setState({ mode: 1 })}>
                    <i className="fa fa-arrow-left align-self-center mr-2" /><span className="align-self-center">Back</span>
                </button>

                {mode > 1 && <Form {...this.state} {...this.props} fnOk={() => this.setState({ mode: 1, selected: null })} />}

            </Animated>

            { /* Modal for Creating / Edit a season (TODO: refactor this) */}
            {modal && <Modal key="ad" isOpen={modal} toggle={() => this.setState({ modal: !this.state.modal })} {...this.props} />}
        </section>
    }
}

export default Ads;
