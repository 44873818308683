import { takeEvery } from 'redux-saga';
import { fork, call, put } from 'redux-saga/effects';
import config from '../../config';
import request from 'superagent';


// Leagues
import {
  getLeaguesForRegistrationSaga, getLeagueSaga, getTeamRegistrationSaga, getFamilyRegistrationSaga, getScoutRegistrationSaga, getLeaguesForPaymentsSaga,
  getLeagueNonSecureSaga, getLeaguesSaga, getLeaguesAdminSaga, getRolesByLeagueSaga, getDOBsByLeagueSaga, validateDOBSaga,
  unvalidateDOBSaga, validateDOBDocumentSaga, unvalidateDOBDocumentSaga, getTournamentSeasonsSaga, createTournamentSeasonSaga, updateTournamentSeasonSaga, deleteTournamentSeasonSaga,
  updateLeaguePointsSaga, fetchLeagueImageSaga, updateLeagueImageSaga, rankingTeamsFetchSaga, getLeagueFeesSaga, getPCVsByLeagueSaga, validatePCVSaga,
  unvalidatePCVSaga, divisionTemplatesFetch, addCampaignSaga, editCampaignSaga, fetchCampaings, validationsSummaryFetch, validationsSummaryByTypeFetch, validationsSummaryByTypeSeasonFetch, getValidationsByLeagueSaga,
  getValidationsByLeagueAndTypeSaga, getValidationsBySeasonAndTypeSaga,
  validateDocumentSaga, validateDocumentSeasonSaga, unvalidateDocumentSaga, unvalidateDocumentSeasonSaga, fetchUserAndAll, fetchUserNotesSaga, addUserNotesSaga, deleteUserNotesSaga, fetchPendingRegistrations,
  rejectPendingRegistration, fetchPendingValidationsSaga, fetchCouponsByLeagueSaga,
  requestPictureReplacementSaga, enforcePictureReplacementSaga, denyPictureReplacementSaga, removePictureReplacementSaga,
  getMembershipProgramsSaga, createMembershipProgramSaga, deleteMembershipProgramSaga, updateMembershipProgramSaga,
  fetchLeagueCurrencySaga
} from './leaguesSaga';

// Seasons
import {
  getSeasonDashboardSaga, getSeasonsSaga, getSeasonGamesSaga, getSeasonCoachesSaga, getSeasonCoachesSaga2,
  getSeasonsForRegistrationSaga, getSeasonsForPaymentsSaga, getSeasonDisclaimerSaga,
  assignQuestionLibraryGroupSaga, archiveQuestionLibraryGroupSaga, getSeasonFiltersSaga,
  getTournamentPointTypes, getAwardsSaga, getShirtsSignagureSaga, setSeasonRosterLimitSaga,
  getFieldsLayoutSaga, getScoreEntryGamesSaga, fetchPointsSystemSaga, getSeasonSaga, fetchSlotsSaga,
  getPCVsBySeasonSaga, validatePCVSeasonSaga,
  unvalidatePCVSeasonSaga, getMedals, getValidationsBySeasonSaga,
  getSeasonViolationsByUserSaga, addUserViolationSaga, getViolationTypesSaga, removeUserViolationSaga,
  getRolesBySeasonSaga, validationsSummaryBySeasonFetchSaga, fetchSeasonScoutRegistrationsSaga,
  assignSeasonScoutRegistrationProgramSaga, removeSeasonScoutRegistrationProgramSaga,
  assignSeasonScoutRegistrationAdjustmentSaga, removeSeasonScoutRegistrationAdjustmentSaga,
  assignQuestionGroupToSeasonSaga, removeQuestionGroupFromSeasonSaga
} from './seasonsSaga';

// Divisions
import {
  setDivisionRosterLimitSaga, getDivisionsSaga, getQuestionsSaga, getPaymentPlans, getTeamsDivisionSaga, getPlayersDivisionSaga,
  updatePlayersDivisionSaga, getCoachesDivisionSaga, updateCoachesDivisionSaga, assignProgramSaga, assignTryoutProgramSaga, removeProgramSaga,
  assignAdjustmentSaga, removeAdjustmentSaga, removeTryoutSaga, assignZipCodeProgramSaga, removeZipCodeProgramSaga, assignFamilyAdjustmentSaga, removeFamilyAdjustmentSaga,
  assignQuestionGroupSaga, removeQuestionGroupSaga, assignScheduledLockSaga, unlockDivisionSaga, lockDivisionSaga, updateDivisionAgeGroupName
} from './divisionsSaga';

// Family
import {
  getFamilyTeams, getFamilyArchivedTeams, getFamilySubscriptions, getFamilyMembers, getAgeGroupsSaga, getCommunitiesSaga, getFamilyTeamsWithAppPlans,
  getFamilyContactSaga, addFamilyMemberSaga, updateFamilyMemberSaga, removeFamilyMemberSaga, assignPhoneNumberSaga, assignEmailSaga, addEmailSaga,
  updateEmailSaga, deleteEmailSaga, addPhoneSaga, updatePhoneSaga, deletePhoneSaga, updateShirtSizeSaga, getRankingAgeGroupsSaga, getFamilyInformation,
  getEmailsToUnsubscribe, getCollegeProfile, togglePersonalInfo, fetchWaiversSaga, getTeamEvaluations
} from './familySaga';

// Teams
import {
  updateTeamSaga, updateResultsSaga, updateAwards, updateShirtsSignatureSaga, updateRankingAgeGroup, getTeamSeasons,
  getTeamVideos, getTeamVideo, updateVideo, deleteVideo, archiveVideo, tagVideo, getTeamSubscriptions,
  subscribeToSQWADTrial, getTeam,
  getTeamSchedule, getTeamOtherEvents, fetchOpponentsSaga, fetchTeamRights, fetchFamilyAttendaceToGameSaga, setFamilyAttendaceToGameSaga,
  fetchUniformByGameSaga, fetchUniformByTeamSaga, setUniformToGameSaga, fetchLineupsSaga
} from './teamsSaga';

// Flights
import {
  getFlightsSaga, flightedTeamsMoveSaga, flightedTeamsSortSaga, flightsSortSaga, flightForceSort
} from './flightsSaga';

// Game Days
import {
  getGameDayRulesSaga, associateGameDayRuleWithFlightSaga, removeGameDayRuleAssociationFromFlight
} from './schedule_manager/gameDayRulesSaga';

// Exceptions
import {
  getExceptionRulesSaga, associateDateExceptionRuleWithTeamSaga, removeDateExceptionRuleAssociationFromTeam
} from './schedule_manager/exceptionRulesSaga';

// Schedule Manager
import {
  getFieldAvailabilityRulesSaga, associateFieldAvailabilityRuleWithFlightSaga, removeFieldAvailabilityRuleAssociationFromFlight
} from './schedule_manager/fieldAvailabilityRulesSaga';

// Games
import {
  getGamesSaga, updateScoreSaga, getGameDetailsSaga
} from './gamesSaga';

// Leads
import {
  getLeadsSaga
} from './leadsSaga';

// Cross-flight Rules
import {
  getCrossFlightRulesSaga
} from './schedule_manager/crossFlightRulesSaga';

// Geolocation
import {
  getCountriesSaga, getStatesSaga, getCountrieCodesSaga, getTimeZonesSaga
} from './geoSaga';

// Payments
import {
  getPaymentsSaga, getProgramsSaga, deletePaymentDueSaga, deleteFamilyAdjustmentIndexSaga, deletePaymentSaga
} from './paymentsSaga';

// Roster
import {
  getRosterTeamsSaga, getRoster, getRosterPublic, getVideosByUser, updateJersey, updatePosition, updateColors, updateCollegeProfile,
  getNetwork, getNetworkCoaches, updateJerseyBatch, updatePositionBatch, updateRole, removeGuestFromGameSaga, addGuestToGameSaga, togglePlayerStatusSaga,
  togglePlayerStatusInGameSaga, save_generic_match_saga, delete_match_saga, unfollow_match_saga, follow_match_saga, match_collaborators_saga,
  fetch_questionaries,
  fetch_custom_attributes_templates_saga, save_lineup_saga, delete_lineup_saga,
  fetch_topics_saga
} from './rosterSaga';

// Service Plans
import { getAppPlans } from './appPlansSaga';

// Waivers
import { getWaiverSaga, getDisclaimerSaga } from './waiversSaga';

// Spotlights
import { getSpotlightSaga } from './spotlightSaga';

// Reports
import {
  getAutoPayReportSaga, getRegistrationReportSaga, getGamesReportSaga,
  getTransactionsReportSaga, getShirtsReportSaga, getTeamsReportSaga, getTeamsRostersReportSaga,
  getRostersReportSaga, getTeamAdminsReport, getRegistrationSnapshotReportSaga, getDonationsReportSaga,
  fetchAccountingSaga, getMasterTransactionsReportSaga
} from './reportsSaga';

// Locations
import { getLocationsSaga, getFamilyLocationsSaga } from './locationsSaga';

import { uploadVideoSaga } from './videoSaga';

// Questions
import {
  getLibrarySaga, createQandASaga, deleteQuestionTextSaga, deleteAnswerTextSaga,
  deleteQandASaga, createQuestionGroupSaga, updateQuestionGroupSaga, getAppliedQuestionsSaga, deleteQuestionGroupSaga
} from './questionsSaga';

// Tryouts
import { getTryoutsSaga, acceptTryoutSaga, returnToTryoutSaga } from './tryoutsSaga';

// Waitlists
import { getWaitlistsSaga, getWaitlistsTeamsSaga, acceptWaitlistsSaga, acceptWaitlistsTeamsSaga, returnToWaitlistsSaga, returnTeamToWaitlistsSaga } from './waitlistsSaga';

// Users
import { updateDOBSaga } from './usersSaga';

import { getReceivedMessagesSaga, getSentMessagesSaga } from './messagesSaga';

// Locks
import { getLocksSaga, createLockSaga, deleteLockSaga, } from './locksSaga';

// Brackets
import { fetchBracketGroupTypesSaga } from './bracketsSaga';

function* getStatusesSaga() {
  yield* takeEvery("STATUS/FETCH", function* (action) {

    try {
      const result = yield call(() => {
        return request.get(`${config.apiEndpoint}/api/v4/common/status`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      });

      if (result && result.success) {
        yield put({
          type: 'STATUSES/SET',
          status: result.status
        });

      } else {
        yield put({
          type: 'STATUSES/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'STATUSES/FETCH_FAILED',
        e
      });
    }
  });
}

function* getCarriersSaga() {
  yield* takeEvery("CARRIERS/FETCH", function* (action) {

    try {
      const result = yield call(() => {
        return request.get(`${config.apiEndpoint}/api/v4/common/carriers`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      });

      if (result && result.success) {
        yield put({
          type: 'CARRIERS/SET',
          carriers: result.carriers
        });

      } else {
        yield put({
          type: 'CARRIERS/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'CARRIERS/FETCH_FAILED',
        e
      });
    }
  });
}

function* getPositionsSaga() {
  yield* takeEvery("POSITIONS/FETCH", function* (action) {

    try {
      const result = yield call(() => {
        return request.get(`${config.apiEndpoint}/api/v4/common/resultPositions`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      });

      if (result && result.success) {
        yield put({
          type: 'POSITIONS/SET',
          positions: result.positions
        });

      } else {
        yield put({
          type: 'POSITIONS/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'POSITIONS/FETCH_FAILED',
        e
      });
    }
  });
}

function* getGameTypesSaga() {
  yield* takeEvery("GAME_TYPES/FETCH", function* (action) {

    try {
      const result = yield call(() => {
        return request.get(`${config.apiEndpoint}/api/v4/common/game_types`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then((data) => {
            return (JSON.parse(data.text))
          });
      });

      if (result && result.success) {
        yield put({
          type: 'GAME_TYPES/SET',
          game_types: result.game_types
        });

      } else {
        yield put({
          type: 'GAME_TYPES/FETCH_FAILED',
          result
        });
      }
    } catch (e) {
      yield put({
        type: 'GAME_TYPES/FETCH_FAILED',
        e
      });
    }
  });
}


function* getSizesSaga() {
  yield* takeEvery("SIZES/FETCH", function* (action) {

    try {

      const result = yield call(() => {
        return request.get(`${config.apiEndpoint}/api/v4/sizes`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then(data => (JSON.parse(data.text)));
      });

      if (result && result.success) {
        yield put({ type: 'SIZES/SET', sizes: result.sizes });
      } else {
        yield put({ type: 'SIZES/FETCH_FAILED', result });
      }

    } catch (e) {
      yield put({ type: 'SIZES/FETCH_FAILED', e });
    }
  });
}

function* getCouponTypes() {
  yield* takeEvery("COUPON_TYPES/FETCH", function* (action) {

    try {

      const result = yield call(() => {
        return request.get(`${config.apiEndpoint}/api/v4/coupons/types`)
          .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
          .then(data => (JSON.parse(data.text)));
      });

      if (result && result.success) {
        yield put({ type: 'COUPON_TYPES/SET', couponTypes: result.types });
      } else {
        yield put({ type: 'COUPON_TYPES/FETCH_FAILED', result });
      }

    } catch (e) {
      yield put({ type: 'COUPON_TYPES/FETCH_FAILED', e });
    }
  });
}


export default function* rootSaga() {
  yield [

    // Leagues
    fork(getLeaguesSaga),
    fork(getLeaguesAdminSaga),
    fork(getSeasonsSaga),
    fork(getSeasonSaga),
    fork(getSeasonDashboardSaga),
    fork(getSeasonGamesSaga),
    fork(getSeasonCoachesSaga),
    fork(getSeasonCoachesSaga2),
    fork(getSeasonsForRegistrationSaga),
    fork(getSeasonDisclaimerSaga),
    fork(getSeasonsForPaymentsSaga),
    fork(assignQuestionLibraryGroupSaga),
    fork(archiveQuestionLibraryGroupSaga),
    fork(getSeasonFiltersSaga),
    fork(getTournamentPointTypes),
    fork(getAwardsSaga),
    fork(getShirtsSignagureSaga),
    fork(setSeasonRosterLimitSaga),
    fork(getFieldsLayoutSaga),
    fork(getScoreEntryGamesSaga),
    fork(fetchPointsSystemSaga),
    fork(getLeagueFeesSaga),
    fork(fetchSlotsSaga),
    fork(getPCVsBySeasonSaga),
    fork(validatePCVSeasonSaga),
    fork(unvalidatePCVSeasonSaga),
    fork(getMedals),
    fork(getValidationsBySeasonSaga),
    fork(getSeasonViolationsByUserSaga),
    fork(addUserViolationSaga),
    fork(getViolationTypesSaga),
    fork(removeUserViolationSaga),
    fork(getRolesBySeasonSaga),
    fork(validationsSummaryBySeasonFetchSaga),
    fork(fetchSeasonScoutRegistrationsSaga),
    fork(assignSeasonScoutRegistrationProgramSaga),
    fork(removeSeasonScoutRegistrationProgramSaga),
    fork(assignSeasonScoutRegistrationAdjustmentSaga),
    fork(removeSeasonScoutRegistrationAdjustmentSaga),
    fork(assignQuestionGroupToSeasonSaga),
    fork(removeQuestionGroupFromSeasonSaga),

    //
    fork(fetchUserAndAll),
    fork(fetchUserNotesSaga),
    fork(addUserNotesSaga),
    fork(deleteUserNotesSaga),
    fork(fetchPendingRegistrations),
    fork(rejectPendingRegistration),
    fork(fetchPendingValidationsSaga),
    fork(fetchCouponsByLeagueSaga),
    fork(requestPictureReplacementSaga),
    fork(enforcePictureReplacementSaga),
    fork(denyPictureReplacementSaga),
    fork(removePictureReplacementSaga),
    fork(createMembershipProgramSaga),
    fork(getMembershipProgramsSaga),
    fork(deleteMembershipProgramSaga),
    fork(updateMembershipProgramSaga),
    fork(fetchLeagueCurrencySaga),


    // Division
    fork(getDivisionsSaga),
    fork(getQuestionsSaga),
    fork(getPaymentPlans),
    fork(getTeamsDivisionSaga),
    fork(getPlayersDivisionSaga),
    fork(updatePlayersDivisionSaga),
    fork(getCoachesDivisionSaga),
    fork(updateCoachesDivisionSaga),
    fork(assignProgramSaga),
    fork(assignTryoutProgramSaga),
    fork(removeProgramSaga),
    fork(assignAdjustmentSaga),
    fork(removeAdjustmentSaga),
    fork(removeTryoutSaga),
    fork(assignZipCodeProgramSaga),
    fork(removeZipCodeProgramSaga),
    fork(assignFamilyAdjustmentSaga),
    fork(removeFamilyAdjustmentSaga),
    fork(assignQuestionGroupSaga),
    fork(removeQuestionGroupSaga),
    fork(assignScheduledLockSaga),
    fork(unlockDivisionSaga),
    fork(lockDivisionSaga),
    fork(setDivisionRosterLimitSaga),
    fork(updateDivisionAgeGroupName),

    // Flights
    fork(getFlightsSaga),
    fork(flightedTeamsMoveSaga),
    fork(flightedTeamsSortSaga),
    fork(getLocationsSaga),
    fork(getFamilyLocationsSaga),
    fork(flightsSortSaga),
    fork(flightForceSort),

    // Game Day
    fork(getGameDayRulesSaga),
    fork(associateGameDayRuleWithFlightSaga),
    fork(removeGameDayRuleAssociationFromFlight),

    // Game Exceptions
    fork(getExceptionRulesSaga),
    fork(associateDateExceptionRuleWithTeamSaga),
    fork(removeDateExceptionRuleAssociationFromTeam),

    // Fields available
    fork(getFieldAvailabilityRulesSaga),
    fork(associateFieldAvailabilityRuleWithFlightSaga),
    fork(removeFieldAvailabilityRuleAssociationFromFlight),

    fork(getGamesSaga),
    fork(updateScoreSaga),
    fork(getGameDetailsSaga),

    fork(getLeadsSaga),

    fork(getCrossFlightRulesSaga),

    fork(getCountriesSaga),
    fork(getStatesSaga),
    fork(getCountrieCodesSaga),
    fork(getTimeZonesSaga),

    // Leagues
    fork(getLeaguesForRegistrationSaga),
    fork(getLeagueSaga),
    fork(getFamilyRegistrationSaga),
    fork(getScoutRegistrationSaga),
    fork(getLeaguesForPaymentsSaga),
    fork(getTeamRegistrationSaga),
    fork(getLeagueNonSecureSaga),
    fork(getRolesByLeagueSaga),
    fork(getDOBsByLeagueSaga),
    fork(validateDOBSaga),
    fork(unvalidateDOBSaga),
    fork(validateDOBDocumentSaga),
    fork(unvalidateDOBDocumentSaga),
    fork(validateDocumentSaga),
    fork(validateDocumentSeasonSaga),
    fork(unvalidateDocumentSaga),
    fork(unvalidateDocumentSeasonSaga),
    fork(getTournamentSeasonsSaga),
    fork(createTournamentSeasonSaga),
    fork(updateTournamentSeasonSaga),
    fork(deleteTournamentSeasonSaga),
    fork(updateLeaguePointsSaga),
    fork(fetchLeagueImageSaga),
    fork(updateLeagueImageSaga),
    fork(rankingTeamsFetchSaga),
    fork(getPCVsByLeagueSaga),
    fork(validatePCVSaga),
    fork(unvalidatePCVSaga),
    fork(divisionTemplatesFetch),
    fork(addCampaignSaga),
    fork(editCampaignSaga),
    fork(fetchCampaings),
    fork(validationsSummaryFetch),
    fork(validationsSummaryByTypeFetch),
    fork(validationsSummaryByTypeSeasonFetch),
    fork(getValidationsByLeagueSaga),
    fork(getValidationsByLeagueAndTypeSaga),
    fork(getValidationsBySeasonAndTypeSaga),

    // Family
    fork(getFamilyMembers),
    fork(getFamilyTeams),
    fork(getFamilyArchivedTeams),
    fork(getFamilySubscriptions),
    fork(getAgeGroupsSaga),
    fork(getRankingAgeGroupsSaga),
    fork(getCommunitiesSaga),
    fork(getFamilyTeamsWithAppPlans),
    fork(getFamilyContactSaga),
    fork(addFamilyMemberSaga),
    fork(updateFamilyMemberSaga),
    fork(removeFamilyMemberSaga),
    fork(assignPhoneNumberSaga),
    fork(assignEmailSaga),
    fork(addEmailSaga),
    fork(updateEmailSaga),
    fork(deleteEmailSaga),
    fork(addPhoneSaga),
    fork(updatePhoneSaga),
    fork(deletePhoneSaga),
    fork(updateShirtSizeSaga),
    fork(getFamilyInformation),
    fork(getEmailsToUnsubscribe),
    fork(getCollegeProfile),
    fork(togglePersonalInfo),
    fork(fetchWaiversSaga),


    fork(getPaymentsSaga),
    fork(getProgramsSaga),
    fork(deletePaymentDueSaga),
    fork(deleteFamilyAdjustmentIndexSaga),
    fork(deletePaymentSaga),

    // Roster
    fork(getRosterTeamsSaga),
    fork(getRoster),
    fork(getRosterPublic),
    fork(getVideosByUser),
    fork(updateJersey),
    fork(updatePosition),
    fork(updateJerseyBatch),
    fork(updatePositionBatch),
    fork(updateColors),
    fork(updateCollegeProfile),
    fork(getNetwork),
    fork(getNetworkCoaches),
    fork(updateRole),
    fork(removeGuestFromGameSaga),
    fork(addGuestToGameSaga),
    fork(togglePlayerStatusSaga),
    fork(togglePlayerStatusInGameSaga),
    fork(save_generic_match_saga),
    fork(delete_match_saga),
    fork(unfollow_match_saga),
    fork(follow_match_saga),
    fork(match_collaborators_saga),
    fork(fetch_questionaries),
    fork(fetch_custom_attributes_templates_saga),
    fork(save_lineup_saga),
    fork(delete_lineup_saga),
    fork(fetch_topics_saga),

    fork(getAppPlans),
    fork(getWaiverSaga),
    fork(getDisclaimerSaga),

    // Commons
    fork(getStatusesSaga),
    fork(getCarriersSaga),
    fork(getPositionsSaga),
    fork(getGameTypesSaga),
    fork(getSizesSaga),
    fork(getCouponTypes),
    fork(getSpotlightSaga),

    // Reports
    fork(getAutoPayReportSaga),
    fork(getRegistrationReportSaga),
    fork(getGamesReportSaga),
    fork(getTransactionsReportSaga),
    fork(getMasterTransactionsReportSaga),
    fork(getShirtsReportSaga),
    fork(getTeamsReportSaga),
    fork(getTeamsRostersReportSaga),
    fork(getRostersReportSaga),
    fork(getTeamAdminsReport),
    fork(getRegistrationSnapshotReportSaga),
    fork(getDonationsReportSaga),
    fork(fetchAccountingSaga),

    // Custom Questions
    fork(getLibrarySaga),
    fork(createQandASaga),
    fork(deleteQuestionTextSaga),
    fork(deleteAnswerTextSaga),
    fork(deleteQandASaga),
    fork(createQuestionGroupSaga),
    fork(updateQuestionGroupSaga),
    fork(getAppliedQuestionsSaga),
    fork(deleteQuestionGroupSaga),

    // Tryotus
    fork(getTryoutsSaga),
    fork(acceptTryoutSaga),
    fork(returnToTryoutSaga),

    // Waitlists
    fork(getWaitlistsSaga),
    fork(getWaitlistsTeamsSaga),
    fork(acceptWaitlistsSaga),
    fork(acceptWaitlistsTeamsSaga),
    fork(returnToWaitlistsSaga),
    fork(returnTeamToWaitlistsSaga),

    // Users 
    fork(updateDOBSaga),

    // Teams
    fork(updateTeamSaga),
    fork(updateResultsSaga),
    fork(updateAwards),
    fork(updateShirtsSignatureSaga),
    fork(updateRankingAgeGroup),
    fork(getTeamSeasons),
    fork(getTeamVideos),
    fork(getTeamSubscriptions),
    fork(getTeamEvaluations),
    fork(subscribeToSQWADTrial),
    fork(getTeamVideo),
    fork(updateVideo),
    fork(deleteVideo),
    fork(archiveVideo),
    fork(tagVideo),
    fork(getTeam),
    fork(getTeamSchedule),
    fork(getTeamOtherEvents),
    fork(fetchOpponentsSaga),
    fork(fetchTeamRights),
    fork(fetchFamilyAttendaceToGameSaga),
    fork(setFamilyAttendaceToGameSaga),
    fork(fetchUniformByGameSaga),
    fork(fetchUniformByTeamSaga),
    fork(setUniformToGameSaga),
    fork(fetchLineupsSaga),

    // ROSTER LOCKS
    fork(getLocksSaga),
    fork(createLockSaga),
    fork(deleteLockSaga),

    // BRACKETS
    fork(fetchBracketGroupTypesSaga),

    // MESSAGES
    fork(getReceivedMessagesSaga),
    fork(getSentMessagesSaga),

    fork(uploadVideoSaga),

  ]

}