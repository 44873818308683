import React, { useMemo, useCallback } from 'react';
import { Animated } from 'react-animated-css';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import _ from 'lodash';
import { getBase64 } from '../../../helpers';
import COLORS from '../../../colors';
import { useDropzone } from 'react-dropzone';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../../common/CustomDatePicker3';

const baseStyle = {
    flex: 1,
    zIndex: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#FFFFFF',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

function StyledDropzone(props) {

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            props.onDrop && props.onDrop(file);
            props.drop && props.drop(file);
        });

    }, [props])

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({ accept: 'image/*', onDrop });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    const { campaing } = props;

    return (
        <div className="container" style={{ position: 'relative' }}>

            <div {...getRootProps({ style })} className="pointer">
                <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
                    className=" d-flex flex-row justify-content-center w-100 h-100">
                    <div style={{ width: 420, height: 420, borderRadius: 210, backgroundColor: '#e5e5e5', opacity: 0.3 }} className="align-self-center" />
                </div>
                <input {...getInputProps()} />
                <div style={{ height: 100 }} />
                {(campaing.ContentImage) && <div className="contain" style={{
                    width: 160, height: 160,
                    background: ['url(', (campaing.ContentImage), ') no-repeat center center'].join('')
                }} />}
                {!(campaing.ContentImage) && <i className="fas fa-audio-description align-self-center gray" style={{ fontSize: '10em' }} />}
                <p className="text-center">Drag 'n' drop the campaign banner here, or click to select the file</p>
                <div style={{ border: '3px solid #2cad3c', borderRadius: 3, width: 200 }} className="p-2 text-center mt-4"><span className="green">UPLOAD IMAGE</span></div>
                <div style={{ height: 100 }} />
            </div>

        </div>
    );
}


class Form extends React.Component {

    state = {
        position: 0,
        campaing: {
            Name: '',
            Link: '',
            CampaignStart: moment(),
            CampaignEnd: moment().add(1, 'month')
        },
        app: true,
        ad: true,
        rf: true,
        rc: true
    }

    componentWillMount = () => {
        if (this.props.selected){
            const { campaing } = this.state, { selected } = this.props;
            campaing.CampaignStart = moment(selected.CampaignStart);
            campaing.CampaignEnd = moment(selected.CampaignEnd);
            campaing.Name = selected.MarketingCampaignName;
            campaing.Link = selected.ContentLink;
            campaing.ContentImage = selected.ContentImage;
            campaing.IdMarketingCampaign = selected.IdMarketingCampaign;

            this.setState({ 
                position: selected.Position,
                campaing,
            });
        }
    }

    componentWillReceiveProps = nextProps => {
        if (nextProps.selected){
            const { campaing } = this.state, { selected } = nextProps;
            campaing.CampaignStart = moment(selected.CampaignStart);
            campaing.CampaignEnd = moment(selected.CampaignEnd);
            campaing.Name = selected.MarketingCampaignName;
            campaing.Link = selected.ContentLink;
            campaing.ContentImage = selected.ContentImage;
            campaing.IdMarketingCampaign = selected.IdMarketingCampaign;

            this.setState({ 
                position: selected.Position,
                campaing,
            });
        }
    }

    save = () => {
        const { leagueActions } = this.props, { campaing } = this.state;
        campaing.MarketingCampaignName = this.txtName.value;
        campaing.ContentLink = this.txtLink.value;
        campaing.Position = this.state.position || 2;
        campaing.AppChoices = _.pick(this.state, 'app', 'ad', 'rf', 'rc');        
        leagueActions && leagueActions.addAdCampaign(campaing);
        this.props.fnOk && this.props.fnOk();
    }

    handleAvatar = e => {
        const { campaing } = this.state;
        getBase64(e.target.files[0]).then(data => {
            campaing.ContentImage = data;
            this.setState({ campaing });
        });
    }

    onSelectCommunity = (community) => {
        const { campaing } = this.state;
        campaing.IdCommunity = community.IdCommunity;
        this.setState({ campaing });
    }

    onSelectLeague = league => {
        const { campaing } = this.state;
        campaing.IdLeague = league.IdLeague;
        this.setState({ campaing }, () => {
            // fetch seasons 
            if (campaing.IdLeague) {
                this.props.fetchSeasons && this.props.fetchSeasons(league.IdLeague);
            }
        });
    }

    onSelectSeason = season => {
        const { campaing } = this.state;
        campaing.IdSeason = season.IdSeason;
        this.setState({ campaing }, () => {
            // fetch divisions? 
            if (campaing.IdSeason) {

            } else { }
        });
    }

    resetAnimation = () => {
        const { position } = this.state;
        this.setState({ position: 0 }, () => {
            this.setState({ position });
        })
    }

    onDrop = (file) => {
        const { campaing } = this.state;
        getBase64(file).then(data => {
            campaing.ContentImage = data;
            this.setState({ campaing });
        });
    }

    handleChangeStart = date => {
        const { campaing } = this.state;
        campaing.CampaignStart = moment(date);
        campaing.CampaignEnd = moment(date).isAfter(moment(campaing.CampaignEnd)) ? moment(date).add(1, 'day') : moment(campaing.CampaignEnd);
        this.setState({ campaing }, () => this.toggleCalendarStart());
    }
    handleChangeEnd = date => {
        const { campaing } = this.state;
        campaing.CampaignStart = moment(date).isBefore(moment(campaing.CampaignStart)) ? moment(date).add(-1, 'day') : moment(campaing.CampaignStart);
        campaing.CampaignEnd = moment(date);
        this.setState({ campaing }, () => this.toggleCalendarEnd());
    }
    toggleCalendarStart = e => {
        e && e.preventDefault()
        this.setState({
            isOpenStartDatepicker: !this.state.isOpenStartDatepicker
        })
    }
    toggleCalendarEnd = e => {
        e && e.preventDefault()
        this.setState({
            isOpenEndDatepicker: !this.state.isOpenEndDatepicker
        })
    }

    render() {
        const { campaing = {}, position } = this.state, { communities, leagues, seasons } = this.props;

        let upcoming = _.chain(seasons)
            .filter(s => moment(s.DateEnd).isAfter(moment()))
            .value();

        return <section>

            <div className="w3-content d-flex flex-row">
                <section className="w-100 pr-4">

                    <h2>Basic Information</h2>

                    <div className="input-group w-100 align-self-center mt-2">
                        <input defaultValue={campaing.Name} type="text" className="form-control p-4" placeholder="Campaing Name" ref={(i) => this.txtName = i} />
                    </div>

                    <div className="input-group w-100 align-self-center mt-2">
                        <input defaultValue={campaing.Link} type="text" className="form-control p-4" placeholder="Link" ref={(i) => this.txtLink = i} />
                    </div>

                    <div className="card p-4 mt-2">
                        <StyledDropzone onDrop={this.onDrop} campaing={campaing} />
                    </div>

                    <h2 className="mt-4">Potential Targets</h2>

                    <div className="input-group align-self-center mt-2">

                        {communities && false && <Dropdown isOpen={this.state.pickCommunity} toggle={() => this.setState({ pickCommunity: !this.state.pickCommunity })} className="form-control p-2 font-16 bg-white black">
                            <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="btn-block text-left no-border d-flex flex-row h-100">
                                <span className="black align-self-center">{campaing.IdCommunity ? _.find(this.props.communities, c => c.IdCommunity === campaing.IdCommunity).CommunityName : 'All Communities'}</span>
                                <i className="ml-auto fas fa-caret-down align-self-center black" />
                            </DropdownToggle>
                            <DropdownMenu className="w-100">
                                <DropdownItem onClick={() => this.onSelectCommunity({})}>
                                    All Communities
                                </DropdownItem>
                                {communities && communities.map((c) => <DropdownItem key={c.IdCommunity} onClick={() => this.onSelectCommunity(c)}>
                                    {c.CommunityName}
                                </DropdownItem>)}
                            </DropdownMenu>
                        </Dropdown>}

                    </div>

                    <div className="input-group align-self-center mt-2">

                        {leagues && <Dropdown isOpen={this.state.pickLeague} toggle={() => this.setState({ pickLeague: !this.state.pickLeague })} className="form-control p-2 font-16 bg-white black">
                            <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="btn-block text-left no-border d-flex flex-row h-100">
                                <span className="black align-self-center">{campaing.IdLeague ? _.find(this.props.leagues, c => c.IdLeague === campaing.IdLeague).LeagueName : 'All Leagues'}</span>
                                <i className="ml-auto fas fa-caret-down align-self-center black" />
                            </DropdownToggle>
                            <DropdownMenu className="w-100">
                                <DropdownItem onClick={() => this.onSelectLeague({})}>
                                    All Leagues
                                </DropdownItem>
                                {leagues && leagues.map((c) => <DropdownItem key={c.IdLeague} onClick={() => this.onSelectLeague(c)}>
                                    {c.LeagueName}
                                </DropdownItem>)}
                            </DropdownMenu>
                        </Dropdown>}

                    </div>

                    {campaing.IdLeague && seasons && <div className="input-group align-self-center mt-2">
                        <Dropdown isOpen={this.state.pickSeason} toggle={() => this.setState({ pickSeason: !this.state.pickSeason })} className="form-control p-2 font-16 bg-white black">
                            <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="btn-block text-left no-border d-flex flex-row h-100">
                                <span className="black align-self-center">{campaing.IdSeason ? _.find(this.props.seasons, c => c.IdSeason === campaing.IdSeason).Name : 'All Seasons'}</span>
                                <i className="ml-auto fas fa-caret-down align-self-center black" />
                            </DropdownToggle>
                            <DropdownMenu className="w-100">
                                <DropdownItem onClick={() => this.onSelectSeason({})}>
                                    All Seasons
                                </DropdownItem>
                                {seasons && upcoming.map((c) => <DropdownItem key={c.IdSeason} onClick={() => this.onSelectSeason(c)}>
                                    {c.SeasonName || c.Name}
                                </DropdownItem>)}
                            </DropdownMenu>
                        </Dropdown>
                    </div>}

                    <div className="d-flex flex-row justify-content-start mt-2">
                        <span className="align-self-center m-1 font-18">Campaign Dates</span>
                        <div className="align-self-center">
                            <DatePicker className="black" showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15}
                                customInput={<CustomDatePicker />} selected={moment(campaing.CampaignStart).utc()} selectsStart
                                startDate={moment(campaing.CampaignStart).utc()} endDate={moment(campaing.CampaignEnd).utc()} onChange={this.handleChangeStart} />
                        </div>
                        <span className="align-self-center m-1">to</span>
                        <div className="align-self-center">
                            <DatePicker className="black" showYearDropdown scrollableYearDropdown yearDropdownItemNumber={15} customInput={<CustomDatePicker />} selected={moment(campaing.CampaignEnd).utc()} selectsStart
                                startDate={moment(campaing.CampaignStart).utc()} endDate={moment(campaing.CampaignEnd).utc()} onChange={this.handleChangeEnd} />
                        </div>
                    </div>

                    <button onClick={this.save} type="button" style={{ border: `1px solid ${COLORS.green}`, borderRadius: 20 }} className="green w-100 p-2 mt-4 align-self-center">
                        Save Campaign</button>

                    <div style={{ height: 100 }} />

                </section>













                <div style={{ width: 300 }} className="h-100">
                    <h2>Position</h2>
                    <div className="d-flex flex-row mt-2">
                        <button style={{ border: position === 1 ? `1px solid ${COLORS.blue}` : null }} className="w-100 card p-2" onClick={() => this.setState({ position: 1 })}>
                            <div style={{ border: '1px solid #CCC', height: 100 }} className="w-100 d-flex flex-column">
                                <div style={{ height: 20, border: '1px solid red' }} />
                            </div>
                        </button>
                        <button style={{ border: position === 2 ? `1px solid ${COLORS.blue}` : null }} className="w-100 card p-2" onClick={() => this.setState({ position: 2 })}>
                            <div style={{ border: '1px solid #CCC', height: 100 }} className="w-100 d-flex flex-column">
                                <div style={{ height: 90, marginTop: 5, border: '1px solid red' }} />
                            </div>
                        </button>
                        <button style={{ border: position === 3 ? `1px solid ${COLORS.blue}` : null }} className="w-100 card p-2" onClick={() => this.setState({ position: 3 })}>
                            <div style={{ border: '1px solid #CCC', height: 100 }} className="w-100 d-flex flex-column justify-content-end">
                                <div style={{ height: 20, border: '1px solid red' }} />
                            </div>
                        </button>
                    </div>
                    <div className="d-flex flex-row mt-2">
                        <h3>Preview</h3>
                        <button className="ml-auto" onClick={this.resetAnimation}><i className="fas fa-redo" /></button>
                    </div>
                    <div className="card cover" style={{ background: `url('/images/pictures/phone.jpeg') no-repeat center center`, height: 450, position: 'relative' }}>
                        {position > 0 && <div className="" style={{ backgroundColor: '#000000CC', position: 'absolute', top: 53, left: 17, right: 17, bottom: 61, overflow: 'hidden' }}>
                            {position === 1 && <Animated className="card contain" animationIn="fadeInLeft" style={{ position: 'absolute', top: 5, left: 5, right: 5, height: 35, background: `url('${campaing.ContentImage}') no-repeat center center ${!campaing.ContentImage ? 'white' : ''}` }} />}
                            {position === 2 && <Animated className="card contain" animationIn="fadeInLeft" style={{ position: 'absolute', top: 5, bottom: 5, left: 5, right: 5, background: `url('${campaing.ContentImage}') no-repeat center center ${!campaing.ContentImage ? 'white' : ''}` }} />}
                            {position === 3 && <Animated className="card contain" animationIn="fadeInLeft" style={{ position: 'absolute', bottom: 5, left: 5, right: 5, height: 35, background: `url('${campaing.ContentImage}') no-repeat center center ${!campaing.ContentImage ? 'white' : ''}` }} />}
                        </div>}
                    </div>

                    <div className="d-flex flex-row mt-2">
                        <h3>Apps</h3>
                    </div>

                    <div className="d-flex flex-row">
                        <button className="p-1 card w-100" style={{ opacity: !this.state.app ? '0.2' : '1' }} onClick={() => this.setState({ app: !this.state.app })}>
                            <img alt="" src="/images/logos/app.png" className="w-100" />
                        </button>
                        <button className="p-1 card w-100" style={{ opacity: !this.state.rc ? '0.2' : '1' }} onClick={() => this.setState({ rc: !this.state.rc })}>
                            <img alt="" src="/images/logos/rc.png" className="w-100" />
                        </button>
                        <button className="p-1 card w-100" style={{ opacity: !this.state.rf ? '0.2' : '1' }} onClick={() => this.setState({ rf: !this.state.rf })}>
                            <img alt="" src="/images/logos/rf.png" className="w-100" />
                        </button>
                        <button className="p-1 card w-100" style={{ opacity: !this.state.ad ? '0.2' : '1' }} onClick={() => this.setState({ ad: !this.state.ad })}>
                            <img alt="" src="/images/logos/ad.png" className="w-100" />
                        </button>
                    </div>
                </div>
            </div>


        </section >
    }
}

export default Form;
